import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
import remove from '../components/assets/remove.png'
import edit from '../components/assets/edit.png'
import { Link } from "react-router-dom";
import axios from "axios";

const EventCard = ({ event, details, icon, from_date, to_date, time, city, place, priceCategory, images, id, identifier, deleteEvent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box backgroundColor={colors.color=" #1A0F0F "} width="100%" ml="-10px" border="16px solid  #1A0F0F ">
      <Box display="flex" justifyContent="space-between">
        <Box>
        <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ color: colors.redAccent[950] }}
          >
            {event}
            <br></br>
          </Typography>
          {/* {icon} */}
          <br></br>
          <Box display="flex" gap="10px">
            {images.map((image,index)=>(
          <img key={index} className="event-img" src={image} alt="x" />
            ))}
          </Box>
          <br></br>
          {/* <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.redAccent[950] }}
          >
            {event}
          </Typography> */}
        </Box>
        <Box>
          {/* <button>Assign</button> */}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.whiteAccent[100]}}>
          {details}
        </Typography>
      </Box>
      <br></br>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h6" sx={{ color: colors.whiteAccent[100] }}>
          {city}( {place} )
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h6" sx={{ color: colors.whiteAccent[100] }}>
          {from_date} - {to_date} ( {time} ) 
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.whiteAccent[100] }}>
         <div style={{ display: 'flex', flexDirection: 'column' }}>
         {priceCategory.pricing.map((type,i)=>(
            <span key={i}>{type.type} &nbsp;&nbsp;{type.price}/- Rs</span>
          ))}  {'\n'} {'\n'}
         </div>
         <br></br>
         <Link to={`/invoices/${id}/${identifier}`}><button className="assign_btn">More info</button></Link>
          {/* <a href="/invoices"><button className="assign_btn">More info</button></a> */}
        </Typography>
        
        <Box display="flex" justifyContent="space-between" gap="5px" mb="5px">
        {/* <a href="/add_checker"><button className="assign_btn">Assign Checker</button></a> */}
        <Link to={`/edit_event/${id}/${identifier}`}><button className="edit_btn"><img  src={edit} alt="" /></button></Link>
        <button onClick={deleteEvent} className="remove_btn"><img  src={remove} alt="" /></button>
        </Box>
      </Box>
    </Box>
  );
};

export default EventCard;
