import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import remove from '../components/assets/remove.png'
import edit from '../components/assets/edit.png'
import { Link } from "react-router-dom";
import axios from "axios";

const CouponCard = ({ code, percentage, limit, from_date, to_date, id, discound_value, event, deleteCoupon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  function formatDateTime(inputDateTime) {
    const date = new Date(inputDateTime);
    const formattedDate = date.toLocaleString(); // You can pass locale options if needed
    return formattedDate;
  }

  


  return (
    <Box backgroundColor={colors.color=" #1A0F0F "} width="100%"  border="16px solid  #1A0F0F ">
      <Box display="flex" justifyContent="space-between">
        <Box>
        <Typography
            variant="h3"
            fontWeight="bold"
            display="flex"
            sx={{ color: colors.redAccent[100] }}
          >
            Event: <Typography fontWeight="bold" variant="h3" sx={{ color: colors.redAccent[950] }}>&nbsp;{event}</Typography> 
          </Typography>
        <Typography
            variant="h4"
            fontWeight="bold"
            display="flex"
            sx={{ color: colors.redAccent[100] }}
          >
            Coupon Code: <Typography fontWeight="bold" variant="h4" sx={{ color: colors.redAccent[950] }}>&nbsp;{code}</Typography> 
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography display="flex" variant="h5" sx={{ color: colors.whiteAccent[100]}}>
          {percentage === null ? (
          <>Discound Value: <Typography fontWeight="bold" variant="h5" sx={{ color: colors.redAccent[950] }}>&nbsp;{discound_value}</Typography></>
          ) : (
            <>Percentage: <Typography fontWeight="bold" variant="h5" sx={{ color: colors.redAccent[950] }}>&nbsp;{percentage}%</Typography></>
            )
          }
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography display="flex" variant="h6" sx={{ color: colors.whiteAccent[100] }}>
        Limit: <Typography fontWeight="bold" variant="h6" sx={{ color: colors.redAccent[950] }}>&nbsp;{limit}</Typography>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h6" sx={{ color: colors.whiteAccent[100] }} display="flex">
          Valid From &nbsp;&nbsp; 
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: colors.redAccent[950] }}
          >{formatDateTime(from_date)} </Typography>
          &nbsp;&nbsp;  to &nbsp;&nbsp;  
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: colors.redAccent[950] }}
          >{formatDateTime(to_date)} </Typography>
        </Typography>
        <Box display="flex" justifyContent="space-between" gap="5px" mb="5px">
        {/* <a href="/add_checker"><button className="assign_btn">Assign Checker</button></a> */}
        <Link to={`/edit_coupon/${id}`}><button className="edit_btn"><img  src={edit} alt="" /></button></Link>
        <button onClick={deleteCoupon} className="remove_btn"><img  src={remove} alt="" /></button>
         &nbsp;&nbsp;
        </Box>
      </Box>

    </Box>
  );
};

export default CouponCard;
