import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import remove from '../components/assets/remove.png'
import edit from '../components/assets/edit.png'
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';

const CheckerCard = ({ event, name, email, id, deleteChecker }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  function formatDateTime(inputDateTime) {
    const date = new Date(inputDateTime);
    const formattedDate = date.toLocaleString(); // You can pass locale options if needed
    return formattedDate;
  }

  const handleSuccess = () => {
    // Display a success toast
    toast.success('Checker Deleted Successfully', {
      position: 'top-right',
      autoClose: 2000,
    });
    
  };




  return (
    <Box backgroundColor={colors.color=" #1A0F0F "} width="100%"  border="16px solid  #1A0F0F ">
      <Box display="flex" justifyContent="space-between">
        <Box>
        <Typography
            variant="h3"
            fontWeight="bold"
            display="flex"
            sx={{ color: colors.redAccent[100] }}
          >
            Event: <Typography fontWeight="bold" variant="h3" sx={{ color: colors.redAccent[950] }}>&nbsp;{event}</Typography> 
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography display="flex" variant="h6" sx={{ color: colors.whiteAccent[100] }}>
        {/* Name: <Typography fontWeight="bold" variant="h6" sx={{ color: colors.redAccent[950] }}>&nbsp;{name}</Typography> */}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography display="flex" variant="h3" sx={{ color: colors.whiteAccent[100] }}>
        Email: <Typography fontWeight="bold" variant="h3" sx={{ color: colors.redAccent[950] }}>&nbsp;{email}</Typography>
        </Typography>
        <Box display="flex" justifyContent="space-between" gap="5px" mb="5px">
        {/* <a href="/add_checker"><button className="assign_btn">Assign Checker</button></a> */}
        {/* <Link to={``}><button className="edit_btn"><img  src={edit} alt="" /></button></Link> */}
        <button onClick={deleteChecker} className="remove_btn"><img  src={remove} alt="" /></button>
         &nbsp;&nbsp;
        </Box>
      </Box>

    </Box>
  );
};

export default CheckerCard;
