import { Box, Button, TextField, Select, MenuItem, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import axios from 'axios';
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import InputLabel from "@mui/material/InputLabel";
import { toast } from 'react-toastify';
import deleteIcon from './assets/remove.png'

const EditEvents = () => {

  let { id, identifier } = useParams();
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #191a1a  inset" };


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const reader = new FileReader();
  const [isPauseBookingAllowed, setIsPauseBookingAllowed] = useState(false)
  const [citys, setCitys] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [termsArray, setTermsArray] = useState([]);
  const [events, setEvents] = useState({
    more_fields: {
      terms: "", // Initialize terms or any other properties you need
    },
  });
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [base64Image, setBase64Image] = useState('');

  const [eventType, setEventType] = useState([{ type: '', price: '' }]);

  const handleEventType = () => {
    setEventType([...eventType, { type: '', price: '' }]);
  };


  const handleTermsConditionsChange = (event) => {
    const updatedTerms = event.target.value;
    const updatedTermsArray = updatedTerms.split('\n');
    setTermsArray(updatedTermsArray);
    // ... any other logic you need to perform on term update
  };





  const [artists, setArtists] = useState([{ artist: '', artist_url: null }]);
  const [images, setImages] = useState(['']);
  const [layoutImage, setLayoutImage] = useState('');

  const handleAddArtist = () => {
    setArtists([...artists, { artist: '', artist_url: null }]);
  };

  const handleAddImage = () => {
    setImages([...images, '']);
  };

  const handleLayoutImageChange = async (event) => {
    const { files } = event.target;

    try {
      var currentImage = files[0];
      const formData = new FormData();
      formData.append('file', currentImage);

      const response = await axios.post(
        'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/upload-file/',
        formData,
        {
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // console.log("return image ", response.data.data.download_url);
      setLayoutImage(response.data.data.download_url)

      // Handle successful response here
    } catch (error) {
      if (error.response) {
        // The request was made, but the server responded with a non-2xx status code
        console.error("Server responded with an error status:", error.response.status);
        console.error("Error response data:", error.response.data);
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received from the server");
      } else {
        // Something else went wrong
        console.error("Error:", error.message);
      }
    }

  };


  useEffect(() => {

    axios.get('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/events-list/')
      .then((response) => {
        // console.log(response.data.data.results);
        const [selectedEvent] = response.data.data.results.filter((event) => {
          return event.id == id;
        })
        console.log("og value", selectedEvent);
        setEvents(selectedEvent);
        setIsPauseBookingAllowed(selectedEvent.pause_booking)
        setLayoutImage(selectedEvent.layout_image)
        setEventType(selectedEvent.ticket_types.pricing)
        setArtists(selectedEvent.more_fields.artists)
        setImages(selectedEvent.images)
        setTermsArray(selectedEvent.more_fields.terms)
      })
      .catch((error) => {
        console.error('Error fetching Events:', error);
      });

    axios.get('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/city-list/')
      .then((response) => {
        setCitys(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Cities:', error);
      });

    axios.get('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/categories-list/')
      .then((response) => {
        // console.log(response.data.data);
        setCategorys(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Categories:', error);
      });


  }, []);

  function checkCategoryOption(catID) {
    if (catID == events.id) {
      return true
    }
    return false
  }

  const handleEventTypeDelete = (index, e) => {
    e.preventDefault();
    // Create a new array with the elements before and after the specified index
    const updatedEventType = [...eventType.slice(0, index), ...eventType.slice(index + 1)];
    // Set the state with the updated array
    setEventType(updatedEventType);
  };

  const handleArtistDetailsDelete = (index, e) => {
    e.preventDefault();
    // Create a new array with the elements before and after the specified index
    const updatedArtistDetails = [...artists.slice(0, index), ...artists.slice(index + 1)];
    // Set the state with the updated array
    setArtists(updatedArtistDetails);
  };

  const handleEventImagesDelete = (index, e) => {
    e.preventDefault();
    // Create a new array with the elements before and after the specified index
    const updatedImageDetails = [...images.slice(0, index), ...images.slice(index + 1)];
    // Set the state with the updated array
    setImages(updatedImageDetails);
  };

  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  const getLoginId = () => {
    return JSON.parse(localStorage.getItem('loginId'));
  };


  const handleInputChange = (index, event, fieldName) => {
    const { value } = event.target;
    const newEventType = [...eventType];
    newEventType[index][fieldName] = value;
    setEventType(newEventType);
  };

  const handleArtistInputChange = async (index, event) => {
    const { name, value, files } = event.target;
    const newArtists = [...artists];

    if (name === 'name') {
      newArtists[index].artist = value;
    } else if (name === 'image') {
      try {
        var currentImage = files[0];
        const formData = new FormData();
        formData.append('file', currentImage);

        const response = await axios.post(
          'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/upload-file/',
          formData,
          {
            headers: {
              'Token': `${getAuthToken()}`,
              'Authorization': `Token ${getAuthToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        // console.log("return image ", response.data.data.download_url);
        newArtists[index].artist_url = response.data.data.download_url;

        // Handle successful response here
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with a non-2xx status code
          console.error("Server responded with an error status:", error.response.status);
          console.error("Error response data:", error.response.data);
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received from the server");
        } else {
          // Something else went wrong
          console.error("Error:", error.message);
        }
      }



    }

    setArtists(newArtists);
  };


  const handleImageChange = async (index, event) => {
    const { name, files } = event.target;
    const newImages = [...images];

    if (name === 'image') {
      try {
        var currentImage = files[0];
        const formData = new FormData();
        formData.append('file', currentImage);

        const response = await axios.post(
          'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/upload-file/',
          formData,
          {
            headers: {
              'Token': `${getAuthToken()}`,
              'Authorization': `Token ${getAuthToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        // console.log("return image ", response.data.data.download_url);
        newImages[index] = response.data.data.download_url;

        // Handle successful response here
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with a non-2xx status code
          console.error("Server responded with an error status:", error.response.status);
          console.error("Error response data:", error.response.data);
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received from the server");
        } else {
          // Something else went wrong
          console.error("Error:", error.message);
        }
      }



    }

    setImages(newImages);
  };

  const handleUpdateChange = (e) => {
    // Update the state with the new value when the input changes
    setEvents({ ...events, [e.target.name]: e.target.value });
  };

  const handleSubUpdateChange = (e) => {
    // Create a copy of the events object
    const updatedEvents = { ...events };

    if (e.target && e.target.name) {
      if (e.target.name === 'terms') {
        updatedEvents.more_fields[e.target.name] = e.target.value.split(',');
      } else {
        // Update the property with the dynamic key using square bracket notation
        updatedEvents.more_fields[e.target.name] = e.target.value;
      }
    }

    // Set the updated object back to the state
    setEvents(updatedEvents);
  };



  const handleSuccess = () => {
    // Display a success toast
    toast.success('Event Updated Successfully', {
      position: 'top-right',
      autoClose: 1000,
    });

    setTimeout(() => {
      window.location = '/home';
    }, 1000)
  };


  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values) => {
    try {
      const clonedValues = JSON.parse(JSON.stringify(events));
      clonedValues.created_by = getLoginId()
      clonedValues.pause_booking = isPauseBookingAllowed
      clonedValues.layout_image = layoutImage
      clonedValues.ticket_types = { "pricing": eventType };
      clonedValues.images = images;
      clonedValues.more_fields.artists = artists;
      clonedValues.more_fields.terms = termsArray;

      console.log('Submitted Data:', clonedValues);



      // Make a POST request to the API endpoint to create an event
      const response = await axios.patch(
        `https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/event-details/${identifier}/`, clonedValues,
        {
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Check if the response status is successful (e.g., HTTP 200)
      if (response.status === 200 || response.status === 201) {
        console.log('Update Response:', response.data);
        handleSuccess()
      } else {
        // Handle unexpected response status codes
        console.error('Unexpected response:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network or request errors
      console.error('Error:', error);

      // Check if the error is due to unauthorized access (401)
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        // You might want to redirect the user to the login page here
      } else {
        // Handle other errors
        alert('An error occurred. Please try again later.');
      }
    }
  };


  // Join the array back into a single string with newline characters
  const termsText = termsArray.join('\n');


  return (
    <Box m="20px" className="event_form main-dashboard">
      <Header title="UPDATE EVENT" subtitle="Update Here" />

      {
        events.more_fields.terms == "" ? (
          <div className="custom_loader">Loading data...</div>
        )
          :

          (
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleFormSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Event Title"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.title}
                      name="title"
                      error={!!touched.title && !!errors.title}
                      helperText={touched.title && errors.title}
                      sx={{
                        gridColumn: "span 4",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />
                    <TextField

                      fullWidth
                      multiline  // This enables multiline input
                      rows={9}    // You can adjust the number of rows as needed
                      variant="filled"
                      type="text"
                      label="Event Description"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.description}
                      name="description"
                      error={!!touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                      sx={{
                        gridColumn: "span 4",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />


                    <TextField
                      fullWidth
                      multiline
                      rows={9}
                      variant="filled"
                      label="Terms and conditions"
                      onBlur={handleBlur}
                      onChange={handleTermsConditionsChange}
                      value={termsText}
                      name="terms"
                      error={!!touched.terms && !!errors.terms}
                      helperText={touched.terms && errors.terms}
                      sx={{
                        gridColumn: "span 4",
                        whiteSpace: "pre-line",  // This property preserves line breaks
                        "& label": {
                          color: colors.color,
                          transform: "translate(0, 1.5px)",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color,
                        },
                      }}
                    />





                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Event Place"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.place}
                      name="place"
                      error={!!touched.place && !!errors.place}
                      helperText={touched.place && errors.place}
                      sx={{
                        gridColumn: "span 2",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      type="time"
                      label="time"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.time}
                      name="time"
                      error={!!touched.time && !!errors.time}
                      helperText={touched.time && errors.time}
                      sx={{
                        gridColumn: "span 2",
                        "& input[type='time']::-webkit-calendar-picker-indicator": {
                          filter: "invert(1)", // Change color as needed
                        },
                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />





                    <TextField
                      fullWidth
                      variant="filled"
                      type="date"
                      label="From Date"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.date}
                      name="date"
                      error={!!touched.fromdate && !!errors.fromdate}
                      helperText={touched.fromdate && errors.fromdate}
                      sx={{
                        gridColumn: "span 2",
                        "& input[type='date']::-webkit-calendar-picker-indicator": {
                          filter: "invert(1)", // Change color as needed
                        },
                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="date"
                      label="To Date"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.date_string}
                      name="date_string"
                      error={!!touched.todate && !!errors.todate}
                      helperText={touched.todate && errors.todate}
                      sx={{
                        gridColumn: "span 2",
                        "& input[type='date']::-webkit-calendar-picker-indicator": {
                          filter: "invert(1)", // Change color as needed
                        },
                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />



                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Location"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.address}
                      name="address"
                      error={!!touched.address && !!errors.address}
                      helperText={touched.address && errors.address}
                      sx={{
                        gridColumn: "span 2",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Age Limit"
                      onBlur={handleBlur}
                      onChange={handleSubUpdateChange}
                      value={events.more_fields.age}
                      name="age"
                      error={!!touched.age && !!errors.age}
                      helperText={touched.age && errors.age}
                      sx={{
                        gridColumn: "span 2",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Language"
                      onBlur={handleBlur}
                      onChange={handleSubUpdateChange}
                      value={events.more_fields.language}
                      name="language"
                      error={!!touched.language && !!errors.language}
                      helperText={touched.language && errors.language}
                      sx={{
                        gridColumn: "span 2",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Duration (Hr)"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.duration}
                      name="duration"
                      error={!!touched.duration && !!errors.duration}
                      helperText={touched.duration && errors.duration}
                      sx={{
                        gridColumn: "span 2",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Convenience Fee (%)"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.handling_fee}
                      name="handling_fee"
                      error={!!touched.handling_fee && !!errors.handling_fee}
                      helperText={touched.handling_fee && errors.handling_fee}
                      sx={{
                        gridColumn: "span 4",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    />


                    {/* <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Available Tickets"
                      onBlur={handleBlur}
                      onChange={handleUpdateChange}
                      value={events.available_tickets}
                      name="available_tickets"
                      error={!!touched.available_tickets && !!errors.available_tickets}
                      helperText={touched.available_tickets && errors.available_tickets}
                      sx={{
                        gridColumn: "span 2",

                        "& label": {
                          color: colors.color = "#FFFFFF", // Change label color
                          transform: "translate(0, 1.5px)", // Move the label up slightly
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: colors.color = "#FFFFFF" // Change the color to your desired color
                        },
                      }}
                    /> */}

                  </Box>

                  <br />
                  <br />

                  <Box sx={{ position: "relative", width: "100%" }}>
                    <Typography sx={{ gridColumn: "span 4", position: "absolute", top: "-24px" }}>
                      Layout Image
                    </Typography>
                    <Box display="flex" gap="10px" marginTop="10px" alignItems="end">
                      <img src={layoutImage} style={{ width: "150px", height: "100px", border: "1px solid #e3e3e3" }} alt="img" />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="file"
                        onBlur={handleBlur}
                        name="image"
                        onChange={handleLayoutImageChange}
                        sx={{ gridColumn: "span 2" }}
                      />
                    </Box>
                  </Box>




                  <Box display="flex" justifyContent="start" mt="20px" gap="20px" backgroundColor={colors.grey[950]} p="10px">
                    <Typography width="50%" sx={{ color: 'red', fontSize: '16px' }}>
                      Ticket Type
                    </Typography>
                    <Box display="flex" justifyContent="space-between" width="50%">
                      <Typography sx={{ color: 'red', fontSize: '17px' }} >
                        Price
                      </Typography>
                      <Typography sx={{ color: 'red',fontSize: '17px', marginLeft:'200px' }} >
                    Count
              </Typography>
                      <button className="add_btn" type="button" width="30%" onClick={handleEventType}>Add More Ticket Types +</button>
                    </Box>
                  </Box>


                  {eventType.map((event, index) => (
                    <Box key={index} display="flex" justifyContent="center" alignItems="center" mt="20px" gap="20px">
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        inputProps={{ style: inputStyle }}
                        label="Ticket Type"
                        onBlur={handleBlur}
                        name={`type_${index}`} // Use a unique name for event type
                        value={event.type}
                        onChange={(e) => handleInputChange(index, e, 'type')} // Pass the field name as a parameter
                        sx={{ gridColumn: "span 2" }}
                        InputLabelProps={{
                          style: {
                            color: colors.color = "#FFFFFF" // Change the color to your desired color
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Ticket Price"
                        onBlur={handleBlur}
                        name={`price_${index}`} // Use a unique name for event price
                        value={event.price}
                        onChange={(e) => handleInputChange(index, e, 'price')} // Pass the field name as a parameter
                        sx={{ gridColumn: "span 2" }}
                        InputLabelProps={{
                          style: {
                            color: colors.color = "#FFFFFF" // Change the color to your desired color
                          },
                        }}
                      />

<TextField
      fullWidth
      variant="filled"
      type="number"
      label="Ticket count"
      onBlur={handleBlur}
      name={`count_${index}`} // Use a unique name for event price
      value={event.count}
      onChange={(e) => handleInputChange(index, e, 'count')} // Pass the field name as a parameter
      sx={{ gridColumn: "span 2" }}
      InputLabelProps={{
        style: {
          color: colors.color="#FFFFFF" // Change the color to your desired color
        },
      }}
    />

                      <button type="button" onClick={((e) => handleEventTypeDelete(index, e))} className="dlt_btn"><img src={deleteIcon} alt="" /></button>
                    </Box>
                  ))}


                  {/* Multiple Artist Adding  */}

                  <Box display="flex" justifyContent="start" mt="20px" gap="20px" backgroundColor={colors.greenAccent[950]} p="10px">
                    <Typography width="50%" sx={{ color: 'red', fontSize: '17px' }}>
                      Artist
                    </Typography>
                    <Box display="flex" justifyContent="space-between" width="50%">
                      <Typography sx={{ color: 'red', fontSize: '16px' }} >
                        Artist Image
                      </Typography>
                      <button className="add_btn" type="button" width="30%" onClick={handleAddArtist}>Add More Artist +</button>
                    </Box>
                  </Box>
                  {artists.map((artist, index) => (
                    <Box key={index} display="flex" justifyContent="center" alignItems="center" mt="20px" gap="20px">
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Artist Name"
                        inputProps={{ style: inputStyle }}
                        onBlur={handleBlur}
                        name="name"
                        value={artist.artist}
                        onChange={(e) => handleArtistInputChange(index, e)}
                        sx={{ gridColumn: "span 2" }}
                        InputLabelProps={{
                          style: {
                            color: colors.color = "#FFFFFF" // Change the color to your desired color
                          },
                        }}
                      />

                      <img src={artist.artist_url} style={{ width: "150px", height: "52px", border: "1px solid #e3e3e3" }} alt="img" />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="file"
                        onBlur={handleBlur}
                        name="image"
                        onChange={(e) => handleArtistInputChange(index, e)}
                        sx={{ gridColumn: "span 2" }}
                      />

                      <button type="button" onClick={((e) => handleArtistDetailsDelete(index, e))} className="dlt_btn"><img src={deleteIcon} alt="" /></button>
                    </Box>
                  ))}

                  {/* Event Images  */}

                  <Box display="flex" justifyContent="start" mt="20px" gap="20px" backgroundColor={colors.grey[950]} p="10px">
                    <Typography width="50%">
                      Event Images
                    </Typography>
                    <Box display="flex" justifyContent="end" width="50%">
                      <button className="add_btn" type="button" width="30%" onClick={handleAddImage}>Add More Image +</button>
                    </Box>
                  </Box>
                  {images.map((image, index) => (
                    <Box key={index} display="flex" justifyContent="center" alignItems="center" mt="20px" gap="20px">

                      <img src={image} style={{ width: "100px", height: "52px", border: "1px solid #e3e3e3" }} alt="img" />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="file"
                        onBlur={handleBlur}
                        name="image"
                        onChange={(e) => handleImageChange(index, e)}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <button type="button" onClick={((e) => handleEventImagesDelete(index, e))} className="dlt_btn"><img src={deleteIcon} alt="" /></button>

                    </Box>
                  ))}

                  <br />

                  <span style={{ display: "flex", minWidth: "300px", alignItems: "baseline" }}>
                    <input style={{ cursor: "pointer" }} type="checkbox" id="alowAdd" name="disc" checked={isPauseBookingAllowed} onChange={() => { setIsPauseBookingAllowed(!isPauseBookingAllowed) }} /> &nbsp;<label style={{ cursor: "pointer" }} htmlFor="alowAdd">Allow Pause Booking</label>
                  </span>



                  <Box display="flex" justifyContent="end" mt="20px" >
                    <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit}>
                      Update Event
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )
      }
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // title: yup.string().required("required"),
  // description: yup.string().required("required"),
  // fromdate: yup.string().required("required"),
  // time: yup.string().required("required"),
  // todate: yup.string().required("required"),
  // place: yup.string().required("required"),
  // city: yup.string().required("required"),
  // address: yup.string().required("required"),
  // host: yup.string().required("required"),
  // age:yup.string().required("required"),
  // terms: yup.string().required("required"),
  // artist:yup.string().required("required"),
  // image: yup.string().required("required"),
  // price: yup.string().required("required"),
  // category: yup.string().required("required"),
  // language: yup.string().required("required"),
  // ticket_types: yup.string().required("required"),  

});
const initialValues = {
  title: "",
  description: "",
  place: "",
  address: "",
  map_location: "",
  ticket_types: "",
  date: "",
  date_string: "",
  time: "",
  duration: "",
  image: "",
  images: "",
  layout_image: "",
  lat: "",
  lon: "",
  more_fields: "",
  terms: "",
  handling_fee: "",
  city: "",
  category: "",
  available_tickets: "",
};

export default EditEvents;