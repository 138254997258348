import { Box, Button, TextField, Select, MenuItem, Typography, useTheme  } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";  
import axios from 'axios';
import { toast } from 'react-toastify';
import CategoryCard from "./CategoryCard";
import { useEffect, useState } from "react";
import { tokens } from "../theme";
import { PartyMode } from "@mui/icons-material";




const Form = () => {
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #111214 inset" };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  const handleSuccess = () => {
    // Display a success toast
    toast.success('Category Added Successfully', {
      position: 'top-right',
      autoClose: 1000,
    });
    
  };

  const handleDeleteSuccess = () => {
    // Display a success toast
    toast.success('Category Deleted Successfully', {
      position: 'top-right',
      autoClose: 1000,
    });
    
  };

  const deleteCategory = (id) => {

    const isConfirmed = window.confirm('Are you sure you want to delete this category?');
    if (isConfirmed) {
    axios.delete(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/category-details/${id}/`,{
      headers: {
        'Token': `${getAuthToken()}`,
        'Authorization': `Token ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
    })
  .then(response => {
    console.log('Category deleted successfully',response);
    handleDeleteSuccess()
  })
  .catch(error => {
    console.error('Error deleting Category', error);
  });
}
}

  const [categorys, setCategorys] = useState([]);

  useEffect(() => {
    axios
      .get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/categories-list/`, {
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log(response.data);
        setCategorys(response.data.data || []); // Fallback to an empty array
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
        setCategorys([]); // Ensure it's always an array
      });
  }, []); // Empty dependency array ensures it runs only once
  


  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values,{ resetForm }) => {
    console.log(getAuthToken());
    try {
      const clonedValues = JSON.parse(JSON.stringify(values));
      clonedValues.token = getAuthToken();
      clonedValues.name = clonedValues.categoryname; // Create a new key-value pair
      delete clonedValues.categoryname; // Delete the old key

      console.log(clonedValues);
      const response = await axios.post('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/create-category/', clonedValues, {
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Check the response status code for success (e.g., HTTP 200)
      if (response.status === 200 || response.status === 201) {
        console.log('Response:', response.data);
        handleSuccess()
      } else {
        // Handle unexpected response status codes
        console.error('Unexpected response:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network or request errors
      console.error('Error:', error);
  
      // Check if the error is due to unauthorized access (401)
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        // You might want to redirect the user to the login page here
        window.location='/';
      } else {
        // Handle other errors
        alert('An error occurred. Please try again later.');
      }
    }
    resetForm();
  };
  
  return (
    <Box m="20px" className="event_form">
      <Header title="Add Category" subtitle="Create a New category" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.categoryname}
                name="categoryname"
                inputProps={{ style: inputStyle }}
                error={!!touched.categoryname && !!errors.categoryname}
                helperText={touched.categoryname && errors.categoryname}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category Description"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ style: inputStyle }}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit}>
                Add Category
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <br />

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
  {Array.isArray(categorys) && categorys.length > 0 ? (
    categorys.map((category) => (
      <CategoryCard
        key={category.id}
        id={category.id}
        category={category.name}
        desc={category?.description}
        deleteCategory={deleteCategory}
      />
    ))
  ) : (
    <Typography>No categories available.</Typography> // Fallback message if categorys is empty
  )}
</div>

    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
   categoryname : yup.string().required("required"),
   description: yup.string().required("required"),
  
});
const initialValues = {
  categoryname: "",
  description: "",
};

export default Form; 