import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from "../../components/Header";
import CheckerAdd from "../../components/CheckerAdd";
import Form from "../../components/Addcoupon";
import StatBox from "../../components/StatBox";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import axios from "axios";
import { Download } from "@mui/icons-material";


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bookingCount, setBookingCount] = useState('');
  const [response, setResponse] = useState(false);
  const [totalAmount, setTotalAmount] = useState('');
  const [bookingList, setBookingList] = useState([]);
  const [ticketTypeCount, setTicketTypeCount] = useState({});
  const [attendanceCount, setAttendanceCount] = useState(0);
  const [totalDetails, setTotalDetails] = useState([]);
  const [user_count, setUser_count] = useState([]);
  const[title,settitle]=useState([]);
  let { id, identifier } = useParams();

  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };
  const handleDownload = async () => {
    try {
      const apiEndpoint = `https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/export-event-bookings/${identifier}/`;

      const response = await axios.get(apiEndpoint, {
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob', // Important for downloading binary data (like a file)
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element and trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${title} Report.csv`; // Specify the desired file name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error downloading data:', error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const data = bookingList;
  const itemsPerPage = 25;

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentData = data.slice(startIndex, endIndex);

  useEffect(()=>{

    axios.get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/event-booking-list/${identifier}/`,{
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          // console.log('Booking List',response.data.data.results);
          setBookingList(response.data.data.results)

        })
        .catch((error) => {
          console.error('Error fetching Events:', error);
        });

  },[currentData])

  useEffect(() => {
    axios
      .get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/event-attendance/${identifier}/`, {
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setTicketTypeCount(response.data.data || {}); // Ensure ticketTypeCount is an object
        setAttendanceCount(Object.values(response.data.data || {}).reduce((acc, count) => acc + count, 0));
        setResponse(true);
      })
      .catch((error) => {
        console.error('Error fetching attendance:', error);
      });
  }, [identifier]);

  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint.
    axios.get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/event-booking-dashboard/${identifier}/`,{
      headers: {
        'Token': `${getAuthToken()}`,
        'Authorization': `Token ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // console.log(response.data.data.bookings_count);
        setBookingCount(response.data.data.bookings_count);
        setTotalAmount(response.data.data.total_amount);
        setResponse(true)
      })
      .catch((error) => {
        console.error('Error fetching Events:', error);
      });
     
      
      
        
      axios.get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/user-count/`,{
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          // console.log(response.data.data.results);
         setUser_count(response.data.data.user_count);
        })
        .catch((error) => {
          console.error('Error fetching Events:', error);
        });


        axios.get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/event-details/${identifier}/`,{
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            // console.log(response.data.data.results);
            settitle(response.data.data.title)
          })
          .catch((error) => {
            console.error('Error fetching Events:', error);
          });


          axios.get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/event-bookings-by-type/${identifier}/`,{
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            // console.log("dt",response.data.data);
            setTotalDetails(response.data.data || [])
          })
          .catch((error) => {
            console.error('Error fetching Events:', error);
          });
  

  }, []);



  return (
    <Box m="20px" className="main-dashboard">
      {
       !response ? (
          <div className="custom_loader">Loading data...</div>
        ) 
      :

      (
        <>
      <div>
      <h1 className="white-text">{title}</h1>
    </div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> 
      

        <Box>
        <Button
            onClick={handleDownload}
            sx={{
              backgroundColor: colors.color = "#201521",
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Download sx={{ mr: "10px" }} />
            Download Reports
            
          </Button>
        </Box>
      </Box>
      

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.color="#1A0F0F"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={bookingCount}
            subtitle="Bookings count"
            icon={
              <EmailIcon
                sx={{ color: colors.color="#E50914", fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.color="#1A0F0F"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalAmount ? totalAmount.toFixed(2) : '0.00'}
            subtitle="Total amount"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.color="#E50914", fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.color="#1A0F0F"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={attendanceCount}
            subtitle="Total Attendence"
            icon={
              <PersonAddIcon
                sx={{ color: colors.color="#E50914", fontSize: "26px" }}
              />
            }
          />
        </Box>
        

        
   
<Box
      flexDirection="column"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={`4px solid ${colors.color="#000000"}`}
      colors={colors.grey[100]}
      p="10px"
      gridColumn="span 6"
      width="100%"
      gridRow="span 2"
    >
      <Box
        gridColumn="span 8"
        backgroundColor={colors.color="#1A0F0F"}
        overflow="auto"
        width="100%"
        height="300px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.color="#000000"}`}
          colors={colors.grey[100]}
          p="10px"
        >
          <Typography
            color={colors.color="#84D2C5"}
            variant="h5"
            fontWeight="600"
            sx={{ margin: "auto" }}
          >
            TOTAL ATTENDANCE ({attendanceCount})
          </Typography>
        </Box>

        <Box
          display={Object.keys(ticketTypeCount).length > 0 ? 'flex' : 'none'}
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.color="#000000"}`}
          colors={colors.grey[100]}
          p="15px"
        >
          <Typography color={colors.color="#E50914"} variant="h5" fontWeight="600">
            TICKET TYPE
          </Typography>
          <Typography color={colors.color="#E50914"} variant="h5" fontWeight="600">
            COUNT
          </Typography>
        </Box>

        {Object.keys(ticketTypeCount).length === 0 ? (
          <div className="no_data_container">No Data!</div>
        ) : (
          Object.entries(ticketTypeCount).map(([ticketType, count], i) => (
            <Box
              key={i}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.color="#000000"}`}
              p="15px"
            >
              <Box>
                <Typography color={colors.color="#FFFFFF"} variant="h5" fontWeight="600">
                  {ticketType}
                </Typography>
              </Box>
              <Box backgroundColor={colors.color="#E50914"} p="5px 10px" borderRadius="4px">
                {count}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Box>

     <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.color="#1A0F0F"}
          overflow="auto"
          
        >
           <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.color="#000000"}`}
          colors={colors.grey[100]}
          p="10px"
        >
          <Typography
            color={colors.color="#84D2C5"}
            variant="h5"
            fontWeight="600"
            sx={{ margin: "auto" }}
          >
            TOTAL TICKETS SOLD
          </Typography>
        </Box>
          
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.color="#000000"}`}
            colors={colors.grey[100]}
            p="15px"
          >
            
            <Typography
                  color={colors.color="#FFFFFF"}
                  variant="h5"
                  fontWeight="600"
                >
                 TICKET TYPE
                </Typography> 
                {/* <Typography
                  color={colors.color="#FFFFFF"}
                  variant="h5"
                  fontWeight="600"
                >
                 Date
                </Typography> */}
                <Typography color={colors.grey[100]}variant="h5"
                  fontWeight="600">
                 AMOUNT
                </Typography>
                
          </Box>
          {totalDetails.length == 0 ?(

            <div className="no_data_container">No Data!</div>
            )
            :
            totalDetails.map((transaction, i) => (
            <Box
              key={`${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.color="#000000"}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.color="#E50914"}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.ticket_type}
                </Typography>
                <Typography color={colors.grey[100]}>
                  Quantity: {transaction.bookings_count}
                </Typography>
              </Box>
              {/* <Box color={colors.grey[100]}sx={styles.centeredText}>{transaction.event_date}</Box> */}
              
              <Box  
                backgroundColor={colors.color="#E50914"}
                p="5px 10px"
                borderRadius="4px"
              >
               ₹{transaction.total_amount ? transaction.total_amount.toFixed(2) : '0.00'}
              </Box>
              
            </Box>
          ))}
        </Box>
     <Box 
     flexDirection="column"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={`4px solid ${colors.color="#000000"}`}
      colors={colors.grey[100]}
      p="10px"
      gridColumn="span 12"
      width="100%"
      gridRow="span 2"
     >

        <Box
          gridColumn="span 8"
          backgroundColor={colors.color="#1A0F0F"}
          overflow="auto"
          width="100%"
          height="300px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.color="#000000"}`}
            colors={colors.grey[100]}
            p="10px"
          >
            <Typography
                  color={colors.color="#84D2C5"}
                  variant="h5"
                  fontWeight="600"
                  sx={{ margin: "auto" }}
                >
                 RECENT TRANSACTIONS
                </Typography> 
                </Box>


          <Box
            display={currentData.length > 0 ? 'flex' : 'none'}
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.color="#000000"}`}
            colors={colors.grey[100]}
            p="15px"
          >
            
            <Typography
                  color={colors.color="#FFFFFF"}
                  variant="h5"
                  fontWeight="600"
                >
                TICKET TYPE
                </Typography> 
                <Typography
                  color={colors.color="#FFFFFF"}
                  variant="h5"
                  fontWeight="600"
                >
                 DATE
                </Typography>
                <Typography color={colors.grey[100]}variant="h5"
                  fontWeight="600">
                 AMOUNT
                </Typography>
                
          </Box>
          {currentData.length == 0 ?
          (
            <div className="no_data_container">No Data!</div>
          ) :
          
          currentData.map((transaction, i) => (
            <Box
              key={i}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.color="#000000"}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.color="#E50914"}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.ticket_type}
                </Typography>
                <Typography color={colors.grey[100]}>
                  Quantity: {transaction.quantity}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.event_date}</Box>
              
              <Box  
                backgroundColor={colors.color="#E50914"}
                p="5px 10px"
                borderRadius="4px"
              >
                ₹{transaction.amount}
              </Box>
              
            </Box>
          ))}
        </Box>

        <Box m={1} display="flex" justifyContent="end" width="100%">
        <button
         className="pagination-btn"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>&nbsp;{`${currentPage} of ${totalPages}`} &nbsp;</span>
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
          </button>
        </Box>
     </Box>
      
        

        <Box
          gridColumn="span 6"
          gridRow="span 4"
          backgroundColor={colors.color="#1A0F0F"}
          p="30px"
        >
         <CheckerAdd />
     
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 5"
          backgroundColor={colors.color="#1A0F0F"}
          p="30px"
        >
        
         <Form/>
        </Box>


      </Box>
</>
      )}
    </Box>
  );
};

export default Dashboard;
